import Django from "./django.svg";
import GraphQL from "./graphql.svg";
import Laravel from "./laravel.svg";
import NodeJS from "./nodejs.svg";
import Rails from "./rails.svg";
import React from "./react.svg";
import VueJS from "./vuejs.svg";
import Android from "./android.svg";
import Apple from "./apple.svg";
import Expo from "./expo.svg";
import Sketch from "./sketch.svg";
import Gatsby from "./gatsby.svg";
import Html from "./html.svg";
import Css from "./css.svg";
import Js from "./js.svg";
import Swift from "./swift.svg";
import PostgreSQL from "./postgresql.svg";
import MySQL from "./mysql.svg";
import Redis from "./redis.svg";
import HighCharts from "./highcharts.svg";
import Aws from "./aws.svg";
import Postmark from "./postmark.svg";
import Sentry from "./sentry.svg";
import InfluxDB from "./influxdb.svg";
import Grafana from "./grafana.svg";
import Kibana from "./kibana.svg";
import Logstash from "./logstash.svg";
import Elasticsearch from "./elasticsearch.svg";
import Scala from "./scala.svg";
import RStudio from "./rstudio.svg";
import Python from "./python.svg";
import BaseWeb from "./baseweb.svg";
import CallPage from "./callpage.svg";
import GraphCMS from "./graphcms.svg";
import NextJS from "./nextjs.svg";
import Pipedrive from "./pipedrive.svg";
import Vercel from "./vercel.svg";
import TypeScript from "./typescript.svg";
import Prismic from "./prismic.svg";
import Crisp from "./crisp.svg";
import Tailwind from "./tailwind.svg";

export default {
  Django: { label: "Django", icon: Django },
  GraphQL: { label: "GraphQL", icon: GraphQL },
  Laravel: { label: "Laravel", icon: Laravel },
  NodeJS: { label: "NodeJS", icon: NodeJS },
  Rails: { label: "Ruby on Rails", icon: Rails },
  React: { label: "ReactJS", icon: React },
  ReactNative: { label: "React Native", icon: React },
  VueJS: { label: "VueJS", icon: VueJS },
  Android: { label: "Android", icon: Android },
  Apple: { label: "iOS", icon: Apple },
  Expo: { label: "Expo", icon: Expo },
  Sketch: { label: "Sketch", icon: Sketch },
  Gatsby: { label: "Gatsby", icon: Gatsby },
  Html: { label: "HTML5", icon: Html },
  Css: { label: "CSS3", icon: Css },
  Js: { label: "JavaScript", icon: Js },
  Swift: { label: "Swift", icon: Swift },
  PostgreSQL: { label: "PostgreSQL", icon: PostgreSQL },
  MySQL: { label: "MySQL", icon: MySQL },
  Redis: { label: "Redis", icon: Redis },
  HighCharts: { label: "HighCharts", icon: HighCharts },
  Aws: { label: "AWS", icon: Aws },
  Postmark: { label: "Postmark", icon: Postmark },
  Sentry: { label: "Sentry", icon: Sentry },
  InfluxDB: { label: "InfluxDB", icon: InfluxDB },
  Grafana: { label: "Grafana", icon: Grafana },
  Kibana: { label: "Kibana", icon: Kibana },
  Logstash: { label: "Logstash", icon: Logstash },
  Elasticsearch: { label: "Elasticsearch", icon: Elasticsearch },
  Scala: { label: "Scala", icon: Scala },
  RStudio: { label: "RStudio", icon: RStudio },
  Python: { label: "Python", icon: Python },
  BaseWeb: { label: "Base Web", icon: BaseWeb },
  CallPage: { label: "CallPage", icon: CallPage },
  GraphCMS: { label: "GraphCMS", icon: GraphCMS },
  NextJS: { label: "NextJS", icon: NextJS },
  Pipedrive: { label: "Pipedrive", icon: Pipedrive },
  Vercel: { label: "Vercel", icon: Vercel },
  TypeScript: { label: "TypeScript", icon: TypeScript },
  Prismic: { label: "Prismic", icon: Prismic },
  Crisp: { label: "Crisp Chat", icon: Crisp },
  Tailwind: { label: "Tailwind CSS", icon: Tailwind },
};
